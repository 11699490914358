// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-access-tsx": () => import("./../../../src/pages/access.tsx" /* webpackChunkName: "component---src-pages-access-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-categories-category-event-reports-title-tsx": () => import("./../../../src/pages/categories/[_category]/event_reports/[_title].tsx" /* webpackChunkName: "component---src-pages-categories-category-event-reports-title-tsx" */),
  "component---src-pages-categories-category-index-tsx": () => import("./../../../src/pages/categories/[_category]/index.tsx" /* webpackChunkName: "component---src-pages-categories-category-index-tsx" */),
  "component---src-pages-categories-category-stories-title-tsx": () => import("./../../../src/pages/categories/[_category]/stories/[_title].tsx" /* webpackChunkName: "component---src-pages-categories-category-stories-title-tsx" */),
  "component---src-pages-categories-index-tsx": () => import("./../../../src/pages/categories/index.tsx" /* webpackChunkName: "component---src-pages-categories-index-tsx" */),
  "component---src-pages-event-reports-index-tsx": () => import("./../../../src/pages/event_reports/index.tsx" /* webpackChunkName: "component---src-pages-event-reports-index-tsx" */),
  "component---src-pages-event-reports-title-tsx": () => import("./../../../src/pages/event_reports/[_title].tsx" /* webpackChunkName: "component---src-pages-event-reports-title-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-menu-tsx": () => import("./../../../src/pages/menu.tsx" /* webpackChunkName: "component---src-pages-menu-tsx" */),
  "component---src-pages-news-id-tsx": () => import("./../../../src/pages/news/[_id].tsx" /* webpackChunkName: "component---src-pages-news-id-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-press-id-tsx": () => import("./../../../src/pages/press/[_id].tsx" /* webpackChunkName: "component---src-pages-press-id-tsx" */),
  "component---src-pages-press-index-tsx": () => import("./../../../src/pages/press/index.tsx" /* webpackChunkName: "component---src-pages-press-index-tsx" */),
  "component---src-pages-stories-index-tsx": () => import("./../../../src/pages/stories/index.tsx" /* webpackChunkName: "component---src-pages-stories-index-tsx" */),
  "component---src-pages-stories-title-tsx": () => import("./../../../src/pages/stories/[_title].tsx" /* webpackChunkName: "component---src-pages-stories-title-tsx" */),
  "component---src-pages-tags-index-tsx": () => import("./../../../src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */),
  "component---src-pages-tags-tag-event-reports-title-tsx": () => import("./../../../src/pages/tags/[_tag]/event_reports/[_title].tsx" /* webpackChunkName: "component---src-pages-tags-tag-event-reports-title-tsx" */),
  "component---src-pages-tags-tag-index-tsx": () => import("./../../../src/pages/tags/[_tag]/index.tsx" /* webpackChunkName: "component---src-pages-tags-tag-index-tsx" */),
  "component---src-pages-tags-tag-stories-title-tsx": () => import("./../../../src/pages/tags/[_tag]/stories/[_title].tsx" /* webpackChunkName: "component---src-pages-tags-tag-stories-title-tsx" */)
}

